import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import Div from 'layout/Div'

import styles from './Support.styles'

const useStyles = createUseStyles(styles)

const Support = ({ errorCode = null, children }) => {

  const { t } = useTranslation()

  const classes = useStyles()
  const navigate = useNavigate()

  if (!errorCode) {
    return children
  }

  let data = null
  if (errorCode === '500') {
    data = {
      status: 500,
      img: 'assets/images/error-500.svg',
      title: t('Internal server error'),
      subtitle: t('Please try again or contact website administrator at {{0}} to get help.', { 0: process.env.REACT_APP_OFFICE_EMAIL })
    }
  }

  if (errorCode === '404') {
    data = {
      status: 404,
      img: 'assets/images/error-404.svg',
      title: t('Oops, We couldn\'t find that page.'),
      subtitle: t('Please try again or contact website administrator at {{0}} to get help.', { 0: process.env.REACT_APP_OFFICE_EMAIL })
    }
  }

  if (errorCode === 'offline') {
    data = {
      img: 'assets/images/error-offline.svg',
      title: t('You are offline.'),
      subtitle: t('You have lost connection to the internet. Please try to reconnect to the internet.'),
    }
  }

  const { status, img, title, subtitle, button = false } = data

  if (!data) {
    return children
  }

  const goHome = () => navigate('/')

  return (
    <Div sizeXS12 centerCenter className={classes.wrapper}>
      <Div sizeXS6 center className={classes.innerWrapper} >
        <div className={classes.errNum}>{status }</div>
        <img className={classes.img} alt="error-500" src={img} />
        <h1>{title}</h1>
        <p>{subtitle}</p>
        {button && (
          <Button
            variant="filled"
            id="error-back-to-home-btn"
            onClick={goHome}
            text={t('Back to Homepage')}
          />
        )}
      </Div>
    </Div>
  )
}

Support.propTypes = {
  errorCode: PropTypes.oneOf([null, '404', '500', 'offline']),
  children: PropTypes.element
}

Support.defaultProps = {
  errorCode: null,
  children: null
}

export default Support
