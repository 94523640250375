import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Close from 'common/icons/Close'
import Text from 'components/Text'
import Div from 'layout/Div'
import isEmpty from 'utils/isEmpty'

import styles from './Chip.styles'

const useStyles = createUseStyles(styles, { name: 'Chip' })
const Chip = ({ children, onRemove, className, ...restProps }) => {

  const classes = useStyles(restProps)

  return (
    <Div row center className={classNames(classes.chip, className)} {...restProps}>
      <Text component="small" color="secondary">
        {children}
      </Text>
      {!isEmpty(onRemove) && (
        <div onClick={onRemove} className={classes.btn}>
          <Close />
        </div>
      )}
    </Div>
  )
}

Chip.propTypes = {
  children: PropTypes.string.isRequired,
  onRemove: PropTypes.func,
  className: PropTypes.any
}

Chip.defaultProps = {
  onRemove: null,
  className: null
}

export default Chip
