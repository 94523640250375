import React, { memo, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

import Button from 'components/Button'
import FeaturedLabel from 'components/FeaturedLabel'
import LoadMore from 'components/LoadMore'
import PageWrapper from 'components/PageWrapper'
import Post from 'components/Post'
import Text from 'components/Text'
import Div from 'layout/Div'
import rest from 'rest'
import { settingsAtomSelector } from 'store/atoms/settings.atom'

const Home = () => {

  const { t } = useTranslation()
  const appName = process.env.REACT_APP_NAME
  const [{ featured }, setSettings] = useRecoilState(settingsAtomSelector())
  const [posts, setPosts] = useState([])

  return (
    <PageWrapper>
      <Div>
        <FeaturedLabel />
        {posts.map((post) => <Post key={post.id} {...post} />)}
        <LoadMore
          restCall={rest.posts} params={{ featured, page_size: 5 }}
          results={posts} setResults={setPosts} noResults={featured && (
            <Div center mt20>
              <Text component="span">
                {t('Currently there are no posts based on what you are following.')}
              </Text>
              <Text component="span">
                {t('Disable featured posts to explore more from {{0}}',{ 0: appName })}
              </Text>
              <Button id="disable-feature-btn"
                mt10
                variant="filled"
                onClick={() => {
                  setSettings({
                    featured: !featured
                  })
                }}
                text={t('Disable Featured Posts')}
              />
            </Div>
          )} />
      </Div>
    </PageWrapper>
  )

}

export default memo(Home)
