import cookie from 'common/cookie'
import rest from 'rest'
import { UserPublic } from 'types/user'
import get from 'utils/get'

export const fetchUser = async () => {
  const token = cookie.get('token')
  if (!token) {
    return null
  }

  const response = await rest.users.all()
  return new UserPublic(get(response, 'results[0]'))
}

export const userAuth = async (email, password) => {
  const { token } = await rest.auth.post({
    email,
    password
  })

  cookie.set('token', token, {
    expires: parseInt(process.env.REACT_APP_TOKEN_EXPIRES)
  })
  const response = await rest.users.all()
  return new UserPublic(get(response, 'results[0]'))
}
