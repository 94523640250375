import React from 'react'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

import Link from 'components/Link'
import Text from 'components/Text'
import Div from 'layout/Div'
import { languageAtom } from 'store/atoms/shared.atom'

const Footer = () => {
  const [lang, setLang] = useRecoilState(languageAtom)
  const { t } = useTranslation()
  const appName = process.env.REACT_APP_NAME

  const languages = {
    en: 'English',
    nl: 'Nederlands',
    de: 'Deutsch',
    sr: 'Srpski',
  }
  const d = new Date()
  let year = d.getUTCFullYear()

  const languageChange = (lang) => () => {
    setLang(lang)
    document.location.reload()
  }

  return (
    <Div sizeXS12 center>
      <Div pb20 center>
        <Div row mb5>
          {Object.keys(languages).map((key) => (
            <Text
              key={key}
              onClick={languageChange(key)}
              component="small"
              mr10
              disabled={key === lang}
            >
              {languages[key]}
            </Text>
          ))}
        </Div>
        <Div row>
          <Text component="small">
            <Link to="/pages/about">{t('About')}</Link>
          </Text>
          &nbsp;
          <Text component="small">
            <Link to="/pages/help">{t('Help')}</Link>
          </Text>
          &nbsp;
          <Text component="small">
            <Link to="/pages/privacy">{t('Privacy')}</Link>
          </Text>
          &nbsp;
          <Text component="small">
            <Link to="/pages/terms">{t('Terms')}</Link>
          </Text>
          &nbsp;
          <Text component="small">
            <Link to="/pages/rules">{t('Rules')}</Link>
          </Text>
        </Div>
        <Text component="small">
          {t('Copyright © {{0}} {{1}}.', { 0: appName , 1: year })}
        </Text>
      </Div>
    </Div>
  )
}

export default Footer
