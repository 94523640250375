import React from 'react'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import Moon from 'common/icons/Moon'
import Sun from 'common/icons/Sun'
import Button from 'components/Button'
import { isDarkModeAtom } from 'store/atoms/shared.atom'

const DarkModeToggle = () => {

  const isDarkMode = useRecoilValue(isDarkModeAtom)
  const setIsDarkModeAtom = useSetRecoilState(isDarkModeAtom)

  const toggleLightDarkMode = () => {
    const nexVal = !isDarkMode
    setIsDarkModeAtom(nexVal)
    localStorage.setItem('isDarkMode', nexVal ? 'on' : 'off')
  }

  let Icon = Moon
  if (isDarkMode) {
    Icon = Sun
  }

  return (
    <Button
      id="header-light-dark-mode"
      variant="icon"
      icon={<Icon />}
      onClick={toggleLightDarkMode}
    />
  )
}

export default DarkModeToggle
