import { breakpoints } from 'layout/Layout'

export default (theme) => ({
  header: {
    position: 'fixed',
    top: 0,
    left: 0,
    right: 0,
    height: theme.headerHeight,
    backgroundColor: theme.background.header,
    zIndex: 100,
    boxShadow: '0 0 8px 0 rgba(0,0,0,0.12)',
    display: 'flex',
    alignItems: 'center',
  },
  headerContainer: {
    alignItems: 'center',
    justifyItems: 'center',
    padding: [5, 20],
    [`@media (max-width: ${breakpoints.md}px)`]: {
      padding: [5, 10],
    },
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      padding: [5, 2]
    },
    maxWidth: 1024,
  },
  left: {
    display: 'flex',
    alignItems: 'center',
  },
  logo: {
    paddingLeft: 5,
    fontSize: 40,
    [`@media (max-width: ${breakpoints.md}px)`]: {
      fontSize: 30,
    },
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      fontSize: 20,
    },
    fontWeight: 900,
    background: theme.logo,
    '-webkit-background-clip': 'text',
    '-webkit-text-fill-color': 'transparent',
    '&:hover, &:hover *': {
      opacity: 1,
    }
  },
  logoDark: {
    background: '-webkit-linear-gradient(0deg, #fff, #E7AD28 100%);',
  }
})
