import { atom, selector } from 'recoil'

import { fetchUser } from 'store/actions/user.actions'

export const userAtom = atom({
  key: 'user',
  default: selector({
    key: 'user/Default',
    get: async () => {
      try {
        return await fetchUser()
      } catch (e) {
        return null
      }
    },
  }),
})
