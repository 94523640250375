import React from 'react'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'

const Icon = ({
  fill,
}) => {
  const theme = useTheme()
  const fillColor = fill || theme.text.primary
  return (
    <svg fill="none"
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      height="24" viewBox="0 0 24 24" width="24">
      <path d="M18 8A6 6 0 0 0 6 8c0 7-3 9-3 9h18s-3-2-3-9M13.73 21a2 2 0 0 1-3.46 0" />
    </svg>
  )
}

Icon.propTypes = {
  fill: PropTypes.string,
}

Icon.defaultProps = {
  fill: undefined,
}
export default Icon
