export default (theme) => ( {
  post: {
    borderBottom: `1px solid ${theme.hr} `
  },
  image: {
    position: 'relative',
    height: '100%',
    aspectRatio: 1.5,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderWidth: 0,
  },
  btn: {
    position: 'absolute',
    top: 5,
    right: 5,
  },
  description: {
    display: 'block',
    textOverflow: 'ellipsis',
    wordWrap: 'break-word',
    overflow: 'hidden',
    maxHeight: '3.6em'
  }
})
