import React from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'

import Link from 'components/Link'
import Text from 'components/Text'
import TogglePostSave from 'components/TogglePostSave'
import TranslatedLabel from 'components/TranslatedLabel'
import UserLabel from 'components/UserLabel'
import Div from 'layout/Div'

import Date from './Date'
import styles from './Post.styles'

const useStyles = createUseStyles(styles, { name: 'Post' })
const Post = ({ id, title, post_images, created_by, short, onChange,
  display_on_day, display_on_month, source_link, language_origin }) => {
  const classes = useStyles()

  const mainImage = post_images[0]

  var domain = ''
  if (source_link) {
    domain = (new URL(source_link).hostname.replace('www.', ''))
  }

  const img = (
    <Link to={`/posts/${id}`}>
      <div
        className={classes.image}
        style={{
          backgroundImage: `url(${process.env.REACT_APP_MEDIA_ROOT}${mainImage.file})`
        }}>
        {!short && (<Date display_on_day={display_on_day} display_on_month={display_on_month} />)}
        {!short && (<TranslatedLabel language_origin={language_origin} />)}
      </div>
    </Link>
  )

  if (short) {
    return (
      <div>
        {img}
        <Div row center spaceBetween mt5>
          <Link to={`/posts/${id}`} sizeXS10>
            <Text component="p" ellipsis sizeXS12>
              {title}
            </Text>
          </Link>
        </Div>
      </div>
    )
  }

  return (
    <Div className={classes.post} sizeXS12 mb40>
      {img}
      <Div row center spaceBetween mb20>
        <Div sizeXS10>
          <Link to={`/posts/${id}`} sizeXS12>
            <Text component="h3" ellipsis mb5>
              {title}
            </Text>
          </Link>
          <Div row center mb5>
            <Text component="small">by&nbsp;</Text>
            {source_link && <Link href={source_link}>
              <Text bold component="small">{domain}</Text>
            </Link>}
            {!source_link && <UserLabel user={created_by} isLink />}
          </Div>
        </Div>
        <TogglePostSave id={id} onChange={onChange} />
      </Div>
    </Div>
  )
}

Post.propTypes = {
  id: PropTypes.string.isRequired,
  title: PropTypes.string.isRequired,
  description: PropTypes.string,
  post_images: PropTypes.arrayOf(PropTypes.object),
  tags: PropTypes.array,
  is_verified: PropTypes.bool,

  created_by: PropTypes.object.isRequired,
  last_update_by: PropTypes.object.isRequired,
  created_at: PropTypes.string.isRequired,
  last_update: PropTypes.string.isRequired,
  display_on_day: PropTypes.string,
  display_on_month: PropTypes.string,
  source_link: PropTypes.string,
  language_origin: PropTypes.string,

  short: PropTypes.bool,

  onChange: PropTypes.func
}

Post.defaultProps = {
  description: null,
  post_images: null,
  tags: [],
  is_verified: false,
  display_on_day: null,
  display_on_month: null,
  source_link: '',
  language_origin: 'en',

  short: false,
  onChange: null
}

export default Post
