import isEmpty from 'utils/isEmpty'

export default (theme) => ({
  chip: {
    padding: [2, 10],
    borderRadius: 26,
    backgroundColor: theme.accent3,
    opacity: 1,
    height: 26,
    maxHeight: 26,
    cursor: ({ onClick }) => isEmpty(onClick) ? 'default' : 'pointer',
  },
  btn: {
    width: 18,
    height: 18,
    cursor: 'pointer',
    marginLeft: 5,
    marginRight: -5,
    '&:hover': {
      opacity: 1,
      '& svg': {
        strokeWidth: 2
      }
    }
  },
})
