export default {
  wrapper: {
    minHeight: '100vh'
  },
  innerWrapper: {
    position: 'relative',
  },
  errNum: {
    position: 'absolute',
    top: 0,
    margin: '0 auto',
    fontSize: '24rem',
    fontWeight: 900,
    color: 'rgba(68,79,96,.15)',
    zIndex: 0
  },
  img: {
    display: 'block',
    margin: '0 auto',
    maxWidth: 660,
    zIndex: 1
  }
}
