import React from 'react'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'

const Icon = ({
  fill,
}) => {
  const theme = useTheme()
  const fillColor = fill || theme.text.primary
  return (
    <svg fill="none"
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      height="24" viewBox="0 0 24 24" width="24">
      <path d="M13 2 3 14h9l-1 8 10-12h-9l1-8z" />

    </svg>
  )
}

Icon.propTypes = {
  fill: PropTypes.string
}

Icon.defaultProps = {
  fill: undefined
}

export default Icon
