import React, { Suspense } from 'react'
import { createUseStyles } from 'react-jss'
import { Route, Routes } from 'react-router-dom'
import PropTypes from 'prop-types'

import Header from 'components/Header'
import Loading from 'components/Loading'
import Div from 'layout/Div'

import Home from './Home'
import styles from './Router.styles'

const Auth = React.lazy(() => import('./Auth'))
const Profile = React.lazy(() => import('./Profile'))

const PostsAlter = React.lazy(() => import('./PostsAlter'))
const PostsView = React.lazy(() => import('./PostsView'))

const Notifications = React.lazy(() => import('./Notifications'))
const Admin = React.lazy(() => import('./Admin'))
const Pages = React.lazy(() => import('./Pages'))

const Quiz = React.lazy(() => import('./Quiz'))
const PostsGroup = React.lazy(() => import('./PostsGroup'))

const useStyles = createUseStyles(styles)
const Router = ({ isLoading }) => {
  const classes = useStyles()

  if (isLoading) {
    return <Loading />
  }
  const isQuizEnabled = process.env.REACT_APP_QUIZ_ENABLED
  return (
    <div className={classes.container}>
      <Header />
      <Div center className={classes.content}>
        <Div sizeXS12>
          <Suspense fallback={<Loading />}>
            <Routes>
              <Route key="home" path="/" element={<Home /> } />
              <Route key="auth" path="auth/*" element={<Auth />} />
              <Route key="profile" path="profile/*" element={<Profile /> } />
              <Route key="posts-view" path="posts/:id" element={<PostsView />} />
              <Route key="posts-create" path="posts/create" element={<PostsAlter action="create" />} />
              <Route key="posts-edit" path="posts/:id/edit" element={<PostsAlter action="edit" />} />
              <Route key="notifications" path="notifications" element={<Notifications /> } />
              <Route key="user-posts" path="users/:id/posts" element={<PostsGroup group="user" /> } />
              <Route key="tag-posts" path="tags/:id/posts" element={<PostsGroup group="tag" /> } />
              <Route key="pages" path="pages/*" element={<Pages /> } />
              <Route key="admin" path="admin/*" element={<Admin /> } />
              {isQuizEnabled && (
                <Route key="quiz" path="quiz/:key" element={<Quiz /> } />
              )}
            </Routes>
          </Suspense>
        </Div>
      </Div>
    </div>
  )
}

Router.propTypes = {
  isLoading: PropTypes.bool
}

Router.defaultProps = {
  isLoading: false
}

export default Router
