export default (theme) => ({
  lastUpdate: {
    position: 'absolute',
    top: '2%',
    right: '1%',
    '-webkit-box-shadow': theme.boxShadow.small,
    boxShadow: theme.boxShadow.small,
    height: 50,
    width: 50,
    borderRadius: theme.borderRadius,
    backgroundColor: theme.background.header,
    lineHeight: '0.5 !important'
  }
})
