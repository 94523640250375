
import assign from 'utils/assign'
import join from 'utils/join'

import defaultConverter from './converter'

class Cookie {
  attributes = {}
  converter = defaultConverter

  constructor(attributes) {
    this.attributes = attributes
  }
  set(key, value, attributes) {
    if (typeof document === 'undefined') {
      return
    }

    attributes = assign({}, this.attributes, attributes)

    if (typeof attributes.expires === 'number') {
      attributes.expires = new Date(Date.now() + attributes.expires)
    }
    if (attributes.expires) {
      attributes.expires = attributes.expires.toUTCString()
    }

    const c_value = join([
      escape(value),
      attributes.expires ? `expires=${attributes.expires}` : null,
      'path=/'
    ].filter((v) => v), '; ')

    document.cookie = key + '=' + c_value
  }

  get(key) {
    if (typeof document === 'undefined' || (arguments.length && !key)) {
      return
    }

    const nameEQ = key + '='
    const ca = document.cookie.split(';')
    for (let i = 0; i < ca.length; i++) {
      let c = ca[i]
      while (c.charAt(0) === ' ') {
        c = c.substring(1, c.length)
      }
      if (c.indexOf(nameEQ) === 0) {
        return c.substring(nameEQ.length, c.length)
      }
    }
    return null
  }

  remove(key, attributes = {}) {
    this.set(key, null, assign({}, attributes, { expires: -1 }))
  }
}

export default new Cookie({ path: '/' })
