import { atom, selectorFamily } from 'recoil'
import { recoilPersist } from 'recoil-persist'

import { userAtom } from 'store/atoms/user.atom'
import getUtils from 'utils/get'

const { persistAtom } = recoilPersist()
export const settingsAtom = atom({
  key: 'settingsAtom',
  default: {},
  effects_UNSTABLE: [persistAtom]
})
export const settingsAtomSelector = selectorFamily({
  key: 'settingsAtomSelector',
  dangerouslyAllowMutability: true,
  get: () => ({ get }) => {
    const state = get(settingsAtom)
    const user = get(userAtom)
    const userId = getUtils(user, ['id'], null)

    return getUtils(state, userId, {
      featured: false,
      ads: true
    } )
  },
  set: () => ({
    set,
    get,
  }, settings) => {
    const state = get(settingsAtom)
    const user = get(userAtom)
    const userId = getUtils(user, ['id'], null)
    if (userId) {
      return set(settingsAtom, {
        ...state,
        [userId]: {
          ...(state[userId] || {}),
          ...settings
        }
      })
    }
  },
})
