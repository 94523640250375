import React from 'react'
import PropTypes from 'prop-types'

import Link from 'components/Link'
import Text from 'components/Text'

const UserLabel = ({ user, isLink, small }) => {
  const { id, email, name } = user
  const label = name || email.split('@')[0]

  if (isLink) {
    return <Link data-testid='user-label-link' to={`/users/${id}/posts?name=${label}`}>
      <Text component={small ? 'small' : 'span'}>{label}</Text>
    </Link>
  }

  return <Text component={small ? 'small' : 'span'} data-testid='user-label-label'>
    {label}
  </Text>
}

UserLabel.propTypes = {
  user: PropTypes.object.isRequired,
  isLink: PropTypes.bool,
  small: PropTypes.bool,
}

UserLabel.defaultProps = {
  isLink: false,
  small: false
}

export default UserLabel
