export default (theme) => ({
  button: {
    textAlign: 'center',
    outline: 'none !important',
    border: '1px solid transparent',
    alignItems: 'center',
    borderRadius: theme.borderRadius,
    boxShadow: 'none',
    padding: '0.5em 1em',
    fontSize: '1rem',
    lineHeight: '1.5',
    backgroundColor: 'transparent',
    color: theme.accent1,
    cursor: 'pointer',
    position: 'relative',
  },
  default: {
    '&:hover': {
      backgroundColor: theme.accent4,
    },
    '&:active': {
      backgroundColor: theme.accent3,
      transition: '0s',
    },
  },
  icon: {
    padding: 6,
    borderRadius: 46,
    fontSize: '1rem',
    lineHeight: 0,
    minWidth: 1,
    '&:hover': {
      backgroundColor: theme.accent4,
      opacity: 0.8
    },
    '&:active': {
      backgroundColor: theme.accent3,
      transition: '0.1s',
    }
  },
  large: {
    fontWeight: 500,
    minWidth: 130,

  },
  small: {
    fontSize: theme.font.small,
    height: 'calc(20px + 1em)'
  },
  filled: {
    backgroundColor: theme.accent2,
    borderColor: theme.accent2,
    '&:hover': {
      backgroundColor: theme.accent3,
      borderColor: theme.accent3,
    },
    '&:active': {
      backgroundColor: theme.accent3,
      borderColor: theme.accent3,
      transition: '0s',
    },
    '& span': {
      color: theme.white
    }
  },

  disabled: {
    opacity: 0.3,
    cursor: 'default',
    '&:hover': {
    },
    '&:active': {
    },
  },
  activeDefault: {
    borderColor: theme.accent3,
  },
  activeIcon: {

  },
  activeFilled: {

  }
})

