import { atom, selector } from 'recoil'
import { recoilPersist } from 'recoil-persist'

export const isDarkModeAtom = atom({
  key: 'isDarkMode',
  default: selector({
    key: 'isDarkMode/Default',
    get: () => {
      return localStorage.getItem('isDarkMode') === 'on'
    }
  }),
})

const { persistAtom } = recoilPersist()
export const languageAtom = atom({
  key: 'language',
  default: 'en',
  effects_UNSTABLE: [persistAtom],
})

export const isCookieAllowAtom = atom({
  key: 'cookie',
  default: false,
  effects_UNSTABLE: [persistAtom],
})

