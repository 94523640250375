import { breakpoints } from './Layout.media'

const oneSize = 100 / 12

const sizes = {}
const keys = Object.keys(breakpoints)
const range = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12]

// export type LayoutSize = {
//   sizeXS1?: boolean,
//   sizeXS2?: boolean,
//   sizeXS3?: boolean,
//   sizeXS4?: boolean,
//   sizeXS5?: boolean,
//   sizeXS6?: boolean,
//   sizeXS7?: boolean,
//   sizeXS8?: boolean,
//   sizeXS9?: boolean,
//   sizeXS10?: boolean,
//   sizeXS11?: boolean,
//   sizeXS12?: boolean,
//
//   sizeSM1?: boolean,
//   sizeSM2?: boolean,
//   sizeSM3?: boolean,
//   sizeSM4?: boolean,
//   sizeSM5?: boolean,
//   sizeSM6?: boolean,
//   sizeSM7?: boolean,
//   sizeSM8?: boolean,
//   sizeSM9?: boolean,
//   sizeSM10?: boolean,
//   sizeSM11?: boolean,
//   sizeSM12?: boolean,
//
//   sizeMD1?: boolean,
//   sizeMD2?: boolean,
//   sizeMD3?: boolean,
//   sizeMD4?: boolean,
//   sizeMD5?: boolean,
//   sizeMD6?: boolean,
//   sizeMD7?: boolean,
//   sizeMD8?: boolean,
//   sizeMD9?: boolean,
//   sizeMD10?: boolean,
//   sizeMD11?: boolean,
//   sizeMD12?: boolean,
//
//   sizeLG1?: boolean,
//   sizeLG2?: boolean,
//   sizeLG3?: boolean,
//   sizeLG4?: boolean,
//   sizeLG5?: boolean,
//   sizeLG6?: boolean,
//   sizeLG7?: boolean,
//   sizeLG8?: boolean,
//   sizeLG9?: boolean,
//   sizeLG10?: boolean,
//   sizeLG11?: boolean,
//   sizeLG12?: boolean,
//
//   sizeXL1?: boolean,
//   sizeXL2?: boolean,
//   sizeXL3?: boolean,
//   sizeXL4?: boolean,
//   sizeXL5?: boolean,
//   sizeXL6?: boolean,
//   sizeXL7?: boolean,
//   sizeXL8?: boolean,
//   sizeXL9?: boolean,
//   sizeXL10?: boolean,
//   sizeXL11?: boolean,
//   sizeXL12?: boolean,
//
//   sizeXXL1?: boolean,
//   sizeXXL2?: boolean,
//   sizeXXL3?: boolean,
//   sizeXXL4?: boolean,
//   sizeXXL5?: boolean,
//   sizeXXL6?: boolean,
//   sizeXXL7?: boolean,
//   sizeXXL8?: boolean,
//   sizeXXL9?: boolean,
//   sizeXXL10?: boolean,
//   sizeXXL11?: boolean,
//   sizeXXL12?: boolean,
// }

keys.forEach((key) => {
  range.forEach((x) => {
    if (key === 'xs') {
      sizes[`size${key.toUpperCase()}${x}`] = {
        width: `${x * oneSize}%`,
      }
      return
    }

    sizes[`size${key.toUpperCase()}${x}`] = {
      [`@media (min-width: ${breakpoints[key]}px)`]: {
        width: `${x * oneSize}%`,
      },
    }
  })
}, {})

export default sizes
