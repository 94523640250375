import React, { useEffect, useState } from 'react'
import { Toaster } from 'react-hot-toast'
import { useTheme } from 'react-jss'
import { useRecoilValue } from 'recoil'

import i18n from 'common/i18n'
import CookieAllow from 'components/CookieAllow'
import ErrorBoundary from 'components/ErrorBoundary'
import Footer from 'components/Footer'
import Vendors from 'components/Vendors'
import Div from 'layout/Div'
import { isDarkModeAtom, languageAtom } from 'store/atoms/shared.atom'

import Router from './Router'

function App() {
  const [loading, setLoading] = useState(true)
  const isDarkMode = useRecoilValue(isDarkModeAtom)
  const language = useRecoilValue(languageAtom)
  const theme = useTheme()

  useEffect(() => {
    Promise.all([
      i18n,
    ]).then(() => {
      i18n.changeLanguage(language)
      setLoading(false)
    })
  }, [])

  useEffect(() => {
    const html = document.getElementsByTagName('html')[0]
    html.style.backgroundColor = theme.background.container
  }, [isDarkMode])

  return (
    <Div>
      <Toaster />
      <ErrorBoundary>
        <Div>
          <CookieAllow />
          <Vendors />
          <Router isLoading={loading} />
          <Footer />
        </Div>
      </ErrorBoundary>
    </Div>
  )
}

export default App
