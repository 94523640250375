const abstractTheme = {
  black: '#4f4f4f',
  lightGray: '#f3f3f4',
  gray: '#d2ddef',
  white: '#fff',

  headerHeight: 60,
  borderRadius: 4,

  font: {
    small: '0.8rem',
    normal: '.95rem',
  },

  notification: {
    info: '#3498db',
    success: '#07bc0c',
    warning: '#f1c40f',
    error: '#e74c3c'
  }

}

const theme = {
  light: {
    ...abstractTheme,
    primary: '#636b42',
    accent0: '#535A38FF',
    accent1: '#7e8463',
    accent2: '#999d84',
    accent3: '#b5b7a5',
    accent4: '#e3e3d2',
    disabled: '#BFBFBFFF',

    background: {
      header: '#f7f7f7',
      container: '#f7f7f7'
    },

    hr: 'rgba(191, 191, 191, .2)',

    text: {
      primary: '#828282',
      secondary: abstractTheme.black
    },

    error: '#B00020',
    logo: '-webkit-linear-gradient(0deg, #535A38FF, #b5b7a5 100%);',

    boxShadow: {
      big: 'rgb(85 85 85 / 20%) 5px 5px 5px -1px, rgb(85 85 85 / 14%) 5px 5px 5px 5px, rgb(85 85 85 / 12%) 5px 5px 5px 5px',
      small: 'rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    },

  },
  dark: {
    ...abstractTheme,
    primary: '#393f50',
    accent0: '#dbd5a8',
    accent1: '#c2be99',
    accent2: '#a9a78b',
    accent3: '#91917d',
    accent4: '#404559FF',
    disabled: 'rgba(191, 191, 191, .2)',

    background: {
      header: '#2f3744',
      container: '#2f3744'
    },

    hr: 'rgba(191, 191, 191, .1)',

    error: '#ff6984',
    logo: '-webkit-linear-gradient(0deg, #e3e3d2, #636b42 100%);',

    boxShadow: {
      big: 'rgb(0 0 0 / 20%) 5px 5px 5px -1px, rgb(0 0 0 / 14%) 5px 5px 5px 5px, rgb(0 0 0 / 12%) 5px 5px 5px 5px',
      small: 'rgb(0 0 0 / 20%) 0px 0px 0px 0px, rgb(0 0 0 / 14%) 0px 1px 1px 0px, rgb(0 0 0 / 12%) 0px 1px 3px 0px',
    },
  },
}

theme.dark.text = {
  primary: theme.dark.accent1,
  secondary: theme.dark.white
}

export default theme
