import React from 'react'
import { useRecoilValue } from 'recoil'

import Gear from 'common/icons/Gear'
import { getIsAdmin } from 'store/selectors/user.selectors'

import Button from '../Button'

const AdminSettingsBtn = () => {
  const isAdmin = useRecoilValue(getIsAdmin)
  if (isAdmin) {
    return (
      <Button id="admin-btn"
        data-testid="admin-btn"
        to="/admin"
        variant="icon"
        icon={<Gear />}/>
    )
  }
  return null

}

export default AdminSettingsBtn
