import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { getClassesAndProps } from './Layout.helpers'
import styles from './Layout.styles'

const useStyles = createUseStyles(styles)

const Layout = ({
  component, children, className, ...rest
}) => {

  const classes = useStyles()

  const [classesProps, restProps] = getClassesAndProps(classes, rest)

  // Create element based on `component` with provided classes as props and restProps
  return React.createElement(component, {
    className: classNames(className, ...Object.values(classesProps)),
    ...restProps,
  }, children)
}

Layout.propTypes = {
  component: PropTypes.string.isRequired,
  children: PropTypes.any,
  className: PropTypes.string
}

Layout.defaultProps = {
  children: undefined,
  className: ''
}

export default Layout
