import cookie from 'common/cookie'
import i18n from 'common/i18n'
import each from 'utils/each'
import isEmpty from 'utils/isEmpty'
import isObject from 'utils/isObject'
import join from 'utils/join'
import replace from 'utils/replace'

class Methods {

  constructor(endpoint) {
    this.endpoint = endpoint
  }

  getHeaders = (body = null) => {
    const isFormData = body instanceof FormData
    const contentType = isFormData ? 'multipart/form-data' : 'application/json'
    const headers = {
      Accept: 'application/json',
      'Content-Type': contentType,
      'Accept-Language': i18n.language,
    }

    if (isFormData) {
      const filenameList = []
      body.forEach((field, key) => {
        if (field instanceof File) {
          filenameList.push(`name="${key}"; filename="${field.name}"`)
        }
      })
      headers['Content-Disposition'] = `attachment; ${join(filenameList, ' ')}`
      delete headers.Accept
      delete headers['Content-Type']
    }

    const token = cookie.get('token')
    if (token) {
      headers['Authorization'] = `Token ${token}`
    }

    return headers
  }

  handleFetch = (url, body = null, method = 'GET') => {

    const bodyData = body instanceof FormData ? body : body && JSON.stringify(body)
    const headers = this.getHeaders(body)

    return fetch(
      url, {
        body: bodyData,
        method,
        headers,
      },
    ).then(async (response) => {

      const { status } = response
      const data = status !== 204 && await response.json()

      if (status === 401) {
        cookie.remove('token')
        document.location.reload()
        throw { errorMessage: i18n.t('Session expired. Please login again.') }
      }

      if (status >= 400 && status < 500) {
        if (data.message && data.code) {
          throw {
            message: join(data.message, ' '),
            code: join(data.code, ' ')
          }
        }
        if (data.non_field_errors) {
          throw { errorMessage: data.non_field_errors }
        }
        if (isObject(data)) {
          throw data
        }
      }
      return data
    })
  }

  generateUrl = (id, params, replacements = null) => {
    if (!this.endpoint) {
      throw new Error('Missing endpoint')
    }

    let url = `${process.env.REACT_APP_API}${this.endpoint}`
    if (id) {
      url += `${id}/`
    }

    if (!isEmpty(params)) {
      const paramsList = []
      each(params, (value, key) => {
        if (isEmpty(value)) {
          return
        }
        paramsList.push(`${key}=${value}`)
      })
      url += `?${join(paramsList, '&')}`
    }

    if (!isEmpty(replacements)) {
      each(replacements, (value, key) => {
        url = replace(url, key, value)
      })
    }

    return url
  }

  all = (params = null, replacements = null) => {
    const url = this.generateUrl(null, params, replacements)
    return this.handleFetch(url)
  }

  get = (id, params = null, replacements = null) => {
    if (!id) {
      throw new Error('Missing id for Methods.get method')
    }
    const url = this.generateUrl(id, params, replacements)
    return this.handleFetch(url)
  }

  post = (data, replacements = null) => {
    const url = this.generateUrl(null, null, replacements)
    return this.handleFetch(url, data, 'POST')
  }

  patch = (id, data, replacements = null) => {
    // if (!id) {
    //   throw new Error('Missing id for Methods.patch method')
    // }
    if (isEmpty(data)) {
      throw new Error('Missing data for Methods.patch method')
    }
    const url = this.generateUrl(id, null, replacements)
    return this.handleFetch(url, data, 'PATCH')
  }

  remove = (id, replacements = null) => {
    if (!id && isEmpty(id)) {
      throw new Error('Missing id for Methods.remove method')
    }
    const url = this.generateUrl(id, null, replacements)
    return this.handleFetch(url, null, 'DELETE')
  }
}

export default Methods
