import React, { useEffect, useRef } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useRecoilState } from 'recoil'

import Button from 'components/Button'
import Text from 'components/Text'
import Div from 'layout/Div'
import { isCookieAllowAtom } from 'store/atoms/shared.atom'

const CookieAllow = () => {

  const { t } = useTranslation()
  const toastRef = useRef(null)
  const [isAllowCookie, setIsAllowCookie] = useRecoilState(isCookieAllowAtom)

  useEffect(() => {
    // Prevent more popups to appear
    if (toastRef.current || isAllowCookie) {
      return
    }

    // Set it as show and prevent more popups to appear
    toastRef.current = true
    toast((tst) => {
      toastRef.current = tst.id
      return (
        <Div>
          <Text component="h3">{t('Privacy Policy')}</Text>
          <Text component="p" mb20>
            {t('By clicking “Accept all cookies”, you agree that {{0}} can store cookies on your device and disclose information in accordance with our Privacy Policy.', { 0: process.env.REACT_APP_NAME })}
          </Text>
          <Div row right>
            <Button id="cookies-accept"
              variant='filled'
              text={'Accept all cookies'}
              onClick={dismissToast}
              ml10
            />
          </Div>
        </Div>
      )
    }, { duration: Infinity })
  }, [])

  const dismissToast = () => {
    setIsAllowCookie(true)
    toast.dismiss(toastRef.current)
    toastRef.current = null
  }

  return (<></>)

}

export default CookieAllow

