import mediaQueries from './Layout.media'
import sizes from './Layout.sizes'

// export type LayoutStyles = {
//   p0?: boolean,
//   ml10?: boolean,
//   ml20?: boolean,
//   mr10?: boolean,
//   mr20?: boolean,
//   mt5?: boolean,
//   mt10?: boolean,
//   mt40?: boolean,
//   mb20?: boolean,
//   mb40?: boolean,
//   pr20?: boolean,
//
//   container?: boolean,
//   center?: boolean,
//   centerCenter?: boolean,
//   right?: boolean,
//   row?: boolean,
//   spaceBetween?: boolean,
// }

export default {
  p0: {
    padding: 0,
  },

  p5: {
    padding: 5,
  },

  pv5: {
    paddingTop: 5,
    paddingBottom: 5
  },

  pl10: {
    paddingLeft: 10
  },

  pt20: {
    paddingTop: 20
  },

  pr20: {
    paddingRight: '20px !important',
  },

  pb20: {
    paddingBottom: '20px !important'
  },
  mt5: {
    marginTop: 5,
  },
  ml5: {
    marginLeft: 5,
  },
  ml10: {
    marginLeft: 10,
  },
  ml20: {
    marginLeft: '20px !important',
  },
  mr5: {
    marginRight: 5,
  },
  mr10: {
    marginRight: 10,
  },
  mr20: {
    marginRight: 20,
  },
  mt0: {
    marginTop: '0 !important',
  },
  mt10: {
    marginTop: '10px !important',
  },
  mt20: {
    marginTop: '20px !important',
  },
  mt40: {
    marginTop: '40px !important',
  },
  mb0: {
    marginBottom: '0 !important'
  },
  mb5: {
    marginBottom: '5px !important'
  },
  mb10: {
    marginBottom: '10px !important'
  },
  mb20: {
    marginBottom: '20px !important'
  },
  mb40: {
    marginBottom: '40px !important',
  },

  container: {
    '-webkit-box-flex': 1,
    '-ms-flex-positive': 1,
    flexGrow: 1,
    margin: '0 auto',
    position: 'relative',
    width: 'auto',
    padding: [0, 15]
  },
  '@media screen and (min-width: 1024px)': {
    container: {
      maxWidth: '960px',
    },
  },
  '@media screen and (min-width: 1216px)': {
    container: {
      maxWidth: '1152px',
    },
  },
  center: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-start',
    flexDirection: 'column'
  },
  centerCenter: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    flexDirection: 'column'
  },

  right: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    flexDirection: 'column'
  },

  row: {
    display: 'flex',
    flexDirection: 'row',
    flexWrap: 'wrap',
  },

  spaceBetween: {
    display: 'flex',
    justifyContent: 'space-between'
  },

  ...mediaQueries,
  ...sizes,
}
