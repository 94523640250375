export default {
  image: {
    position: 'relative',
    height: '100%',
    aspectRatio: 1,
    backgroundSize: 'cover',
    backgroundPosition: 'center center',
    borderWidth: 0,
  },
  title: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  }
}
