import React from 'react'
import { useRecoilValue } from 'recoil'

import Add from 'common/icons/Add'
import { getCanUserCreatePost } from 'store/selectors/user.selectors'

import Button from '../Button'

const CreatePostBtn = () => {
  const canCreate = useRecoilValue(getCanUserCreatePost)

  if (canCreate) {
    return (
      <Button id="create-post-btn"
        data-testid="create-post-btn"
        to='/posts/create'
        variant="icon"
        icon={<Add />} />
    )
  }
  return null
}

export default CreatePostBtn
