import React, { useEffect, useState } from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import Text from 'components/Text'
import Div from 'layout/Div'
import rest from 'rest'
import { userAtom } from 'store/atoms/user.atom'
import { getFollowedTags, getUser } from 'store/selectors/user.selectors'
import { UserPublic } from 'types/user'
import isEmpty from 'utils/isEmpty'

export default function TagsSuggestions() {

  const navigate = useNavigate()

  const { t } = useTranslation()

  const [tags, setTags] = useState([])
  const user = useRecoilValue(getUser)
  const setStateUser = useSetRecoilState(userAtom)

  const followedTags = useRecoilValue(getFollowedTags)

  useEffect(() => {
    const exclude = followedTags.map((item) => item.id)
    rest.tags.all({ suggested: true, exclude: exclude.join(',') }).then((response) => {
      setTags(response.results)
    })
  }, [followedTags])

  const follow = (id, name) => () => {
    if (isEmpty(user)) {
      toast(t('Please login or create account to follow {{0}}.', { 0: name }) )
      navigate('/auth')
      return
    }
    rest.usersFollow.post({ tag_id: id }, { ':id': user.id }).then((response) => {
      setStateUser(new UserPublic({
        ...user,
        ...response
      }))
      toast.success(t('You are now following {{0}}.', { 0: name }))
    }).catch(() => {
      toast.error(t('Oops something went wrong, please try again.'))
    })
  }

  if (isEmpty(tags)) {
    return null
  }

  return (
    <Div ml10>
      <Text bold component="p">
        {t('Tags to follow')}
      </Text>
      <Div row>
        {tags.map(({ id, name }) => (
          <Text
            onClick={follow(id, name)}
            key={id}
            component={'small'}
            mr5
          >
            {name}
          </Text>)
        )}
      </Div>
    </Div>
  )
}
