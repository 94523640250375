import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'

import Chip from 'components/Chip'
import Div from 'layout/Div'
import { languageAtom } from 'store/atoms/shared.atom'

import styles from './TranslatedLabel.styles'

const useStyles = createUseStyles(styles, { name: 'TranslatedLabel' })

const TranslatedLabel = ({ language_origin }) => {
  const classes = useStyles()
  const language = useRecoilValue(languageAtom)
  const { t } = useTranslation()

  if (language_origin === language) {
    return null
  }

  return (
    <Div row className={classes.translated}>
      <Chip title={t('This post was translated by third party tool')}>
        {t('Translated')}
      </Chip>
    </Div>
  )

}

TranslatedLabel.propTypes = {
  language_origin: PropTypes.string.isRequired
}

export default TranslatedLabel
