import Methods from './Methods'

export default ({
  auth: new Methods( '/api/auth/'),
  register: new Methods('/api/auth/register'),
  activation: new Methods('/api/auth/activation'),
  resendActivation: new Methods('/api/auth/activation'),
  resetPassword: new Methods('/api/auth/reset_password'),
  resetPasswordSet: new Methods('/api/auth/reset_password/set'),
  users: new Methods('/api/users/'),
  usersFollow: new Methods('/api/users/:id/follow/'),
  usersSavePost: new Methods('/api/users/:id/save_post/'),

  passwordChange: new Methods('/api/users/:id/password/'),
  usersAuthors: new Methods('/api/users/:userId/authors/'),

  posts: new Methods('/api/posts/'),
  postsStage: new Methods('/api/posts/:id/stage/'),
  postImages: new Methods('/api/posts/:postId/images/'),

  tags: new Methods('/api/tags/'),

  adminUsers: new Methods('/admin/users/'),
  adminAuthors: new Methods('/admin/authors/'),

  adminPosts: new Methods('/admin/posts/'),
  adminTags: new Methods('/admin/tags/'),

  adminTranslate: new Methods('/admin/translate/'),

  errors: new Methods('/api/errors/'),

  notifications: new Methods('/api/notifications/'),
  notificationsToggle: new Methods('/api/notifications/:id/read/toggle/'),
  notificationsReadAll: new Methods('/api/notifications/read/all/'),

})
