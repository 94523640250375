import React, { useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'

import Bell from 'common/icons/Bell'
import Button from 'components/Button'
import rest from 'rest'

import styles from './Notification.styles'

const useStyles = createUseStyles(styles, { name: 'Notification' })
const Notification = () => {
  const classes = useStyles()
  const [hasNotification, setHasNotification ] = useState(false)

  const checkNotifications = () => {
    rest.notifications.all().then((response) => {
      setHasNotification(response.count)
    })
  }

  useEffect(() => {
    checkNotifications()
    const interval = setInterval(() => {
      checkNotifications()
    }, parseInt(process.env.REACT_APP_NOTIFICATION_INTERVAL))
    return () => clearInterval(interval)
  }, [])

  return (
    <Button id="notification-btn"
      variant="icon"
      to="/notifications"
      icon={(
        <div className={classes.icon}>
          <Bell />
          {hasNotification > 0 && (<div className={classes.badge} />)}
        </div>
      )}/>
  )
}

export default Notification
