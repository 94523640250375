// type Breakpoints = {
//   xs: number
//   sm: number
//   md: number
//   lg: number
//   xl: number
//   xxl: number
// } & Record<string, number>

export const breakpoints = {
  xs: 0,
  sm: 576,
  md: 769,
  lg: 992,
  xl: 1201,
  xxl: 1441,
}

// export type LayoutMedia = {
//   hideXS?: boolean,
//   hideSM?: boolean,
//   hideMD?: boolean,
//   hideLG?: boolean,
//   hideXL?: boolean,
//   hideXXL?: boolean,
//
//   hideDownXS?: boolean,
//   hideDownSM?: boolean,
//   hideDownMD?: boolean,
//   hideDownLG?: boolean,
//   hideDownXL?: boolean,
//   hideDownXXL?: boolean,
//
//   hideUpXS?: boolean,
//   hideUpSM?: boolean,
//   hideUpMD?: boolean,
//   hideUpLG?: boolean,
//   hideUpXL?: boolean,
//   hideUpXXL?: boolean,
// }

const mediaQueries = {}
const keys = Object.keys(breakpoints)
keys.forEach((key, idx) => {
  mediaQueries[`hideDown${key.toUpperCase()}`] = {}
  mediaQueries[`@media (max-width: ${breakpoints[key]}px)`] = {
    transition: ['width', '250ms', 'ease'],
    [`hideDown${key.toUpperCase()}`]: {
      display: 'none !important',
    },
  }

  mediaQueries[`hideUp${key.toUpperCase()}`] = {}
  mediaQueries[`@media (min-width: ${breakpoints[key]}px)`] = {
    transition: ['width', '250ms', 'ease'],
    [`hideUp${key.toUpperCase()}`]: {
      display: 'none',
    },
  }

  mediaQueries[`hide${key.toUpperCase()}`] = {}
  if (breakpoints[keys[idx + 1]]) {
    mediaQueries[`@media (min-width: ${breakpoints[key]}px) and (max-width: ${breakpoints[keys[idx + 1]]}px)`] = {
      transition: ['width', '250ms', 'ease'],
      [`hide${key.toUpperCase()}`]: {
        display: 'none',
      },
    }
  } else {
    mediaQueries[`@media (min-width: ${breakpoints[key]}px)`] = {
      transition: ['width', '250ms', 'ease'],
      [`hide${key.toUpperCase()}`]: {
        display: 'none',
      },
    }
  }
})

export default mediaQueries
