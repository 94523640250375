import React from 'react'
import { useTheme } from 'react-jss'

const Icon = () => {
  const theme = useTheme()
  const fillColor = theme.text.primary
  return (
    <svg fill="none"
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      height="24" viewBox="0 0 24 24" width="24">
      <path d="m12 19 7-7 3 3-7 7-3-3z" />
      <path d="m18 13-1.5-7.5L2 2l3.5 14.5L13 18l5-5zM2 2l7.586 7.586" />
      <circle cx={11} cy={11} r={2} />
    </svg>
  )
}
export default Icon
