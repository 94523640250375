import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useRecoilValue } from 'recoil'

import Link from 'components/Link'
import Text from 'components/Text'
import UserLabel from 'components/UserLabel'
import Div from 'layout/Div'
import { recentPostsAtom } from 'store/atoms/post.atom'
import isEmpty from 'utils/isEmpty'

import styles from './RecentPosts.styles'

const useStyles = createUseStyles(styles, { name: 'RecentPosts' })

const RecentPosts = () => {

  const { t } = useTranslation()

  const classes = useStyles()
  const recentPosts = useRecoilValue(recentPostsAtom)

  if (isEmpty(recentPosts)) {
    return null
  }

  return (
    <Div data-testid="recent-posts" ml10>
      {recentPosts.map(({ id, title, post_images, created_by }) => (
        <Link key={id} to={`/posts/${id}`}>
          <Div sizeXS12 row mb10>
            <Div sizeXS3
              className={classes.image}
              style={{
                backgroundImage: `url(${process.env.REACT_APP_MEDIA_ROOT}${post_images[0].file})`
              }}/>
            <Div sizeXS9>
              <Div sizeXS12 pl10 mb5>
                <Text component="p" bold ellipsis sizeXS12>
                  {title}
                </Text>
              </Div>
              <Div sizeXS12 pl10>
                <Text component="small">
                  {t('By')} <UserLabel user={created_by} />
                </Text>
              </Div>
            </Div>
          </Div>
        </Link>
      ))}
    </Div>
  )
}

export default RecentPosts
