import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useRecoilValue } from 'recoil'

import Chip from 'components/Chip'
import Div from 'layout/Div'
import { settingsAtomSelector } from 'store/atoms/settings.atom'

import styles from './FeaturedLabel.styles'

const useStyles = createUseStyles(styles, { name: 'FeaturedLabel' })

export default function FeaturedLabel() {

  const { t } = useTranslation()

  const classes = useStyles()
  const settings = useRecoilValue(settingsAtomSelector())

  if (!settings.featured) {
    return null
  }
  return (
    <Div row className={classes.featured}>
      <Chip >
        {t('Featured')}
      </Chip>
    </Div>
  )
}
