export default (theme) => ({
  icon: {
    position: 'relative'
  },
  badge: {
    // TODO: Create animation when appearing
    position: 'absolute',
    top: 1,
    right: 4,
    width: 10,
    height: 10,
    backgroundColor: theme.error,
    borderRadius: 10,
  },
})
