import React from 'react'
import { useTheme } from 'react-jss'

export default function Logo() {
  const theme = useTheme()
  const fillColor = theme.text.primary
  return (
    <svg
      width={80}
      height={48}
      fill={fillColor}
      viewBox="0 0 184 112"
    >
      <path d="M60.626 2.241C61.224.896 60.328.15 59.282.15c-.598 0-1.195.3-1.644 1.046-.15.15-4.333 10.758-4.333 10.758S21.93 23.606 4.45 38.846c-6.275 5.379-4.482 7.022-3.137 7.022.298 0 .597 0 .747-.15.896-.746 1.046-1.792 4.78-4.482 18.677-14.641 44.375-25.1 44.674-24.801C36.572 53.04 37.618 50.5 37.618 50.649c-.598.448-25.25 12.102-22.411 24.503 1.195 5.677 8.964 5.827 9.711 6.126.299 0-11.355 27.939-11.355 28.536-.15.747 0 1.943 1.793 1.943h.299c1.195 0 1.344-1.793 13.297-30.629 28.686-2.69 107.424-39.145 114.297-62.9C145.34 9.71 138.617 4.034 130.1 1.942 124.573.598 118.447 0 112.32 0 85.876 0 57.788 10.608 57.788 10.16c-.15.299 3.287-8.666 2.838-7.919zm10.608 37.501c-.298-.149-.597-.149-1.045-.149-5.827 0-27.64 7.62-27.79 8.666-.448 0 2.54-6.425 13.446-33.468.3-.298 28.687-11.205 55.73-11.205 7.32 0 20.02 1.195 25.548 5.528 12.251 9.711-15.24 28.537-28.537 36.605-44.523 26.594-72.91 31.674-78.14 31.674.897-2.69 7.77-19.722 9.712-23.756.149-.597 0-.597 3.585-2.24 7.62-3.736 15.24-6.425 26.296-8.666 2.39-.449 2.839-1.793 1.195-2.989zM35.377 55.73c-.449 1.046-8.815 21.963-8.815 22.112-2.54-.597-8.068-.299-8.068-4.631 0-8.218 16.584-17.182 16.883-17.481zm90.839 18.525c.15 0 .449.15.598.15 4.333 0 10.907-10.907 17.032-17.63 1.046-1.345 0-2.241-1.942-2.839-10.16 12.102-9.114 11.206-11.953 14.343-.896 1.046-2.39 2.241-2.39 2.241-1.195 0 3.436-15.986 5.677-18.078 1.196-.598.3-2.69-.896-2.69-.299 0-.747.15-1.046.449-2.69 1.494-11.654 23.457-5.08 24.054zm8.666-30.18h.448c2.69 0 3.287-4.482.299-4.482-2.839 0-3.287 3.885-.747 4.482z" />
      <path d="M140.56 69.026c5.826 0 18.675-8.665 23.158-12.251.747-.598 1.045-2.092-1.196-2.839-2.39 1.494-17.182 11.355-21.216 11.803-.896 0-1.045-.15-.896-1.344.598-4.034 5.23-10.459 7.172-11.953l.597-.299s.299.299.897.299c.298 0 .597-.15.896-.448.747-.747.598-1.793-.15-2.54-.298-.448-.746-.598-1.344-.598-1.046 0-2.54.747-4.183 2.39-6.724 5.977-10.907 17.78-3.736 17.78z" />
      <path d="M160.73 68.13c.448.15.896.299 1.344.299 4.333 0 10.16-7.47 10.459-12.252.747.448 3.585.747 6.424.747 1.345 0 2.69 0 3.735-.15 2.092-.149 1.644-2.24-.15-2.838-.896.15-1.792.15-2.688.15-4.034 0-7.62-.748-9.264-1.495-1.195-.448-2.988 0-3.735-.597a1.614 1.614 0 0 0-1.046-.448c-.896 0-1.942.747-2.988 2.091-3.137 3.885-6.574 11.953-2.092 14.493zm4.78-12.7s4.034.897 3.736 1.793c-1.494 3.586-4.93 6.425-7.47 7.47-.3-1.045 1.195-5.826 3.734-9.263z" />
    </svg>
  )
}
