import React from 'react'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'

const Icon = ({
  fill,
}) => {
  const theme = useTheme()
  const fillColor = fill || theme.text.primary
  return (
    <svg height="24" viewBox="0 0 24 24" width="24"
      fill="none"
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}>
      <circle cx={12} cy={12} r={5} />
      <path d="M12 1v2M12 21v2M4.22 4.22l1.42 1.42M18.36 18.36l1.42 1.42M1 12h2M21 12h2M4.22 19.78l1.42-1.42M18.36 5.64l1.42-1.42" />

    </svg>
  )
}

Icon.propTypes = {
  fill: PropTypes.string
}

Icon.defaultProps = {
  fill: undefined
}
export default Icon
