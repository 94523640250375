export default (theme) => ({
  default: {
    fontWeight: 300,
    fontStyle: 'normal',
    fontFamily: 'Montserrat',
  },
  bold: {
    fontWeight: '900 !important',
  },
  center: {
    textAlign: 'center !important',
    alignItems: 'center',
  },
  primary: {
    color: theme.text.primary,
  },
  secondary: {
    color: theme.text.secondary,
  },
  background: {
    color: theme.background,
  },
  disabled: {
    opacity: 0.3,
  },

  h1: {
    fontSize: '2rem',
    fontWeight: 600,
    lineHeight: 1.125,
  },
  h2: {
    fontSize: '1.5rem',
    fontWeight: 600,
    lineHeight: 1.125,
  },
  h3: {
    fontWeight: 600,
    lineHeight: 1.125,
  },
  h4: {
    fontWeight: 600,
    margin: ['0.5em', 0]
  },
  h5: {
    fontWeight: 600,
    margin: 0
  },
  h6: {

  },
  p: {
    margin: ['0.5em', 0],
    fontSize: '.95rem',
    lineHeight: 1.3
  },
  li: {
    margin: ['0.5em', 0],
    fontSize: '.95rem',
    lineHeight: 1.3
  },
  small: {
    fontSize: '.85rem'
  },
  clickable: {
    '&:hover': {
      cursor: 'pointer',
      fontWeight: 'bold'
    }
  },
  ellipsis: {
    textOverflow: 'ellipsis',
    whiteSpace: 'nowrap',
    overflow: 'hidden'
  },
  rows: {
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    display: '-webkit-box',
    '-webkit-box-orient': 'vertical',
    '-webkit-line-clamp': ({ rows }) => rows,
  },
  em: {
    fontStyle: 'italic'
  }
})
