import React from 'react'
import { createUseStyles } from 'react-jss'
import { Link as LinkDom } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import { LayoutBase } from 'layout/Layout'
import Span from 'layout/Span'

import styles from './Link.styles'

const useStyles = createUseStyles(styles)
const Link = ({ to, href, text, active = false, children, target, ...restProps })=> {
  const classes = useStyles()
  const props = {
    className: classNames(classes.link, {
      [classes.active]: active
    }),
    to,
    href,
    target
  }

  return (
    <Span {...restProps}>
      {to && (
        <LinkDom {...props}>
          {text || children}
        </LinkDom>
      )}
      {href && (
        <a {...props}>
          {text || children}
        </a>
      )}
    </Span>
  )
}

Link.propTypes = {
  to: PropTypes.string,
  href: PropTypes.string,
  text: PropTypes.string,
  active: PropTypes.bool,
  children: PropTypes.any,
  ...LayoutBase,
}

Link.defaultProps = {
  to: null,
  href: null,
  text: null,
  active: false,
  children: null
}

export default Link
