import React from 'react'
import { toast } from 'react-hot-toast'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import PropTypes from 'prop-types'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import Save from 'common/icons/Save'
import Button from 'components/Button'
import rest from 'rest'
import { userAtom } from 'store/atoms/user.atom'
import { getUser } from 'store/selectors/user.selectors'
import { UserPublic } from 'types/user'
import contains from 'utils/contains'
import isEmpty from 'utils/isEmpty'

export default function TogglePostSave({ id, onChange }) {

  const navigate = useNavigate()
  const { t } = useTranslation()
  const user = useRecoilValue(getUser)
  const setStateUser = useSetRecoilState(userAtom)

  const isSaved = contains(user.savedPosts, id)

  const save = () => {
    if (isEmpty(user)) {
      toast(t('Please login or create account to save post for later.'))
      navigate('/auth')
      return
    }
    rest.usersSavePost.post({ post_id: id }, { ':id': user.id }).then((response) => {
      setStateUser(new UserPublic({
        ...user,
        ...response
      }))
      if (isSaved) {
        toast(t('This post is removed from saved.'))
      } else {
        toast.success(t('This post is added to saved.'))
      }
      if (onChange) {
        onChange()
      }
    }).catch(() => {
      toast.error(t('Oops something went wrong, please try again.'))
    })
  }

  return (
    <Button id="post-save-btd" variant="icon" onClick={save}>
      <Save small saved={isSaved} />
    </Button>
  )
}

TogglePostSave.propTypes = {
  id: PropTypes.string.isRequired,
  onChange: PropTypes.func
}

TogglePostSave.defaultProps = {
  onChange: null
}
