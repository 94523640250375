import React from 'react'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'

const Icon = ({ small, saved }) => {
  const theme = useTheme()
  const fillColor = theme.text.primary
  const size = small ? 22 : 30

  return (
    <svg fill={saved ? fillColor : 'none'}
      stroke={saved ? 'none' : fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      height={size} viewBox="0 0 24 24" width={size}>
      <path d="m19 21-7-5-7 5V5a2 2 0 0 1 2-2h10a2 2 0 0 1 2 2z" />

    </svg>
  )
}

Icon.propTypes = {
  small: PropTypes.bool,
  saved: PropTypes.bool,
}

Icon.defaultProps = {
  small: false,
  saved: false
}

export default Icon
