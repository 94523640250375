import React from 'react'
import { useTheme } from 'react-jss'

const Icon = () => {
  const theme = useTheme()
  const fillColor = theme.text.primary
  return (
    <svg fill="none"
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      height="24" viewBox="0 0 24 24" width="24">
      <path d="M19 21H5a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h11l5 5v11a2 2 0 0 1-2 2z" />
      <path d="M17 21v-8H7v8M7 3v5h8" />
    </svg>
  )
}
export default Icon
