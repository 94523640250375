import React, { useContext, useEffect, useState } from 'react'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Layout, { LayoutBase } from 'layout/Layout'
import capitalize from 'utils/capitalize'
import isEmpty from 'utils/isEmpty'

import { FormContext } from '../Form/Form'
import Text from '../Text'

import styles from './Button.styles'

const useStyles = createUseStyles(styles)

const Button = ({
  id,
  variant = 'default',
  type = 'button',
  text,
  icon,
  small = false,
  large = false,
  disabled = false,
  active = false,
  onClick,
  to,
  className,
  children,
  ...restPros
}) => {
  const classes = useStyles()
  const navigate = useNavigate()
  const form = useContext(FormContext)
  const [isDisabled, setIsDisabled] = useState(disabled)

  useEffect(() => {
    if (!isEmpty(form)) {
      setIsDisabled( !form.dirty && type === 'submit')
    }
  },[form?.dirty])

  const handleOnClick = (e) => {
    if (isDisabled) {
      e.preventDefault()
      e.stopPropagation()
      return
    }
    if (to) {
      navigate(to)
    }
    if (onClick) {
      setIsDisabled(true)
      Promise.all([
        onClick()
      ]).finally(() => {
        setIsDisabled(false)
      })
      // TODO: set button as disabled when clicked and wait for promise to be resolved before enabling it again
    }
  }

  return (
    <Layout
      key={id}
      data-testid={`test-${id}`}
      aria-label={id}
      component="button"
      type={type}
      row
      centerCenter
      className={classNames(
        className,
        classes.button,
        classes[variant],
        {
          [classes.small]: small,
          [classes.large]: large,
          [classes.disabled]: isDisabled,
          [classes[`active${capitalize(variant)}`]]: active,
        })}
      onClick={handleOnClick}
      {...restPros}
    >
      {icon }
      {text && (
        <Text component="span" bold>
          {text}
        </Text>
      )}
      {children}
    </Layout>
  )
}
Button.propTypes = {
  id: PropTypes.string.isRequired,
  variant: PropTypes.oneOf(['default', 'filled', 'icon']),
  type: PropTypes.oneOf(['button', 'submit']),
  text: PropTypes.string,
  children: PropTypes.any,
  icon: PropTypes.element,
  small: PropTypes.bool,
  large: PropTypes.bool,
  disabled: PropTypes.bool,
  active: PropTypes.bool,
  onClick: PropTypes.func,
  to: PropTypes.string,
  className: PropTypes.string,
  ...LayoutBase
}

Button.defaultProps = {
  variant: 'default',
  type: 'button',
  text: null,
  children: null,
  icon: null,
  small: false,
  large: false,
  disabled: false,
  active: false,
  onClick: null,
  to: null,
  className: null
}
export default Button
