export default (theme) => ({
  link: {
    color: theme.accent0,
    width: '100%',
    textDecoration: 'none',
    fontWeight: 'bold !important',
    '&:hover': {
      opacity: 0.8
    },
    '&:hover small': {
      opacity: 1
    }
  },
  active: {
    color: theme.accent0,
    fontWeight: 'bold',
    textDecoration: 'underline',
  }
})
