/**
 * Filter out classes from rest props
 *
 * @param classes
 * @param restProps
 *
 * @returns {[{}, {}]}
 */
export const getClassesAndProps = (classes, restProps) => {
  const classesKeys = Object.keys(classes)
  const retProps = {}

  // Iterate over received props to determine if is class or prop
  const retClasses = Object.keys(restProps).reduce((diff, key) => {
    // Check if rest prop is class name
    if (classesKeys.includes(key)) {
      // Check if rest prop is enabled
      if (restProps[key]) {
        return {
          ...diff,
          [key]: classes[key],
        }
      }
      return diff
    }

    // If its not class set it in rest props
    retProps[key] = restProps[key]
    return diff
  }, {})

  return [retClasses, retProps]
}
