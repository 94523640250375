
export class UserPublic {
  id
  name
  email
  isAdmin = false
  user_author
  isAuthor = false
  lastLogin
  followedTags = []
  followedUsers = []
  savedPosts = []

  constructor({ id, name, email, is_admin, user_author, last_login, followed_tags, followed_users, saved_posts }) {
    this.id = id
    this.name = name || ''
    this.email = email || ''
    this.isAdmin = is_admin || false
    this.user_author = user_author || { stage: AuthorStage.NONE }
    this.isAuthor = user_author?.stage === AuthorStage.APPROVED
    this.lastLogin = new Date(last_login)
    this.followedTags = followed_tags
    this.followedUsers = followed_users
    this.savedPosts = saved_posts
  }
}

export const AuthorStage = {
  NONE: 'NONE',
  REQUESTED: 'REQUESTED',
  IN_REVIEW: 'IN_REVIEW',
  APPROVED: 'APPROVED',
  REJECTED: 'REJECTED'
}

