import React from 'react'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'

const Icon = ({
  fill,
}) => {
  const theme = useTheme()
  const fillColor = fill || theme.text.primary
  return (
    <svg fill="none"
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      height="24" viewBox="0 0 24 24" width="24">
      <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4M10 17l5-5-5-5M15 12H3" />
    </svg>
  )
}

Icon.propTypes = {
  fill: PropTypes.string
}

Icon.defaultProps = {
  fill: undefined
}
export default Icon
