import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import QuizIcon from 'common/icons/Quiz'
import Link from 'components/Link'
import ProfileArea from 'components/ProfileArea/ProfileArea'
import RecentPosts from 'components/RecentPosts'
import TagsSuggestions from 'components/TagsSuggestions'
import Text from 'components/Text'
import Div from 'layout/Div'

const PageWrapper = ({ children }) => {

  const { t } = useTranslation()

  const isQuizEnabled = process.env.REACT_APP_QUIZ_ENABLED
  return (
    <Div sizeXS12 pt20 row>
      <Div sizeXS12 sizeSM10 sizeMD7>
        {children}
      </Div>
      <Div hideDownSM sizeSM1 />
      <Div hideDownMD sizeMD4 mt20>
        <Div mb20>
          <ProfileArea />
        </Div>
        {isQuizEnabled && (
          <Div mb20 ml10>
            <Link to="/quiz/daily" >
              <Div row center>
                <QuizIcon />
                <Text component="span" ml5>{t('Quiz')}</Text>
              </Div>
            </Link>
          </Div>
        )}
        <Div mb20>
          <RecentPosts />
        </Div>
        <Div mb20>
          <TagsSuggestions />
        </Div>
      </Div>

    </Div>
  )

}
PageWrapper.propTypes = {
  children: PropTypes.any.isRequired
}

export default PageWrapper
