import { breakpoints } from './Layout.media'

// TODO: Define base props
const LayoutBase = {}

export { default } from './Layout'

export {
  breakpoints,
  LayoutBase
}

