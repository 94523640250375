import React, { useEffect, useRef, useState } from 'react'
import { toast } from 'react-hot-toast'
import PropTypes from 'prop-types'

import Layout from 'layout/Layout'
import contains from 'utils/contains'
import each from 'utils/each'
import isEmpty from 'utils/isEmpty'

export const FormContext = React.createContext(null)

const Form = ({
  autoComplete,
  children,
  onSubmit,
  errors: propErrors,
  ...restProps
}) => {

  const errorListeners = useRef({})
  const dataListeners = useRef({})
  const data = useRef({})
  const groups = useRef({})
  const [dirty, setDirty] = useState(false)

  const runErrorListeners = (errors) => {
    return Promise.all(Object.values(errorListeners.current).map((listener) => {
      return listener({
        isSubmitted: true,
        errors
      })
    }))
  }

  const runDataListeners = (fieldName, data) => {
    if (isEmpty(dataListeners.current[fieldName])) {
      return
    }
    each(dataListeners.current[fieldName], (listener) => {
      listener(data)
    })
  }

  useEffect(() => {
    if (!isEmpty(propErrors)) {
      setDirty(true)
      if (propErrors.errorMessage) {
        toast.error(propErrors.errorMessage)
      }
      runErrorListeners(propErrors)
    }
  }, [propErrors])

  useEffect(() => {
    return () => {
      errorListeners.current = {}
      dataListeners.current = {}
    }
  }, [])

  const handleOnSubmit = (e) => {
    e.preventDefault()

    runErrorListeners(null).then((results) => {
      if (!contains(results, true)) {
        setDirty(false)
        onSubmit(data.current)
      }
    }).catch(() => {
      // has errors
    })
  }

  const addErrorListener = (id, listener) => {
    if (!errorListeners.current[id]) {
      errorListeners.current[id] = listener
    }
  }

  const removeErrorListener = (id) => {
    if (errorListeners.current[id]) {
      delete errorListeners.current[id]
    }
  }

  const addDataChangeListener = (id, listener) => {
    if (!dataListeners.current[id]) {
      dataListeners.current[id] = []
    }
    dataListeners.current[id].push(listener)
  }

  const setData = (id, value, isInitial = false, group) => {
    if (group) {
      if (isEmpty(groups.current[group])) {
        groups.current[group] = {}
      }
      groups.current[group][id] = value

      const groupValues = []
      each(groups.current[group], (value) => {
        if (!isEmpty(value)) {
          groupValues.push(value)
        }
      })

      data.current[group] = groupValues.join(',')
      runDataListeners(group, groupValues.join(','))
    } else {
      data.current[id] = value
      runDataListeners(id, value)
    }

    if (!isInitial) {
      setDirty(true)
    }
  }

  return (
    <Layout noValidate component="form" onSubmit={handleOnSubmit}
      autoComplete={autoComplete} {...restProps}>
      <FormContext.Provider value={{
        addDataChangeListener,
        addErrorListener,
        removeErrorListener,
        setData,
        dirty
      }}>
        {children}
      </FormContext.Provider>
    </Layout>
  )
}

Form.propTypes = {
  autoComplete: PropTypes.string,
  children: PropTypes.any.isRequired,
  onSubmit: PropTypes.func.isRequired,
  errors: PropTypes.object
}

Form.defaultProps = {
  autoComplete: 'off',
  errors: {}
}

export default Form
