import { useEffect } from 'react'
import { useLocation } from 'react-router-dom'

import isEmpty from 'utils/isEmpty'

export default function GoogleVendor() {

  let location = useLocation()

  useEffect(() => {
    window.dataLayer?.push({
      event: 'pageview',
      page: {
        url: location.pathname + location.search,
      }
    })
  }, [location.pathname, location.search])

  useEffect(() => {
    if (isEmpty(process.env.REACT_APP_GA_TAG_ID)) {
      return
    }

    let script = document.createElement('script')

    script.src = `https://www.googletagmanager.com/gtag/js?id=${process.env.REACT_APP_GA_TAG_ID}`
    script.async = true

    document.body.appendChild(script)

    script = document.createElement('script')

    script.type = 'text/javascript'
    script.async = true
    script.innerHTML = `
      window.dataLayer = window.dataLayer || [];
      function gtag(){dataLayer.push(arguments);}
      gtag('js', new Date());

      gtag('config', '${process.env.REACT_APP_GA_TAG_ID}');
    `

    document.head.appendChild(script)

  }, [])

  return null
}
