export default {
  read: function(value, key) {
    return value.replace(/(%[\dA-F]{2})+/gi, decodeURIComponent(key))
  },
  write: function(value, key) {
    return encodeURIComponent(value).replace(
      /%(2[346BF]|3[AC-F]|40|5[BDE]|60|7[BCD])/g,
      decodeURIComponent(key)
    )
  }
}
