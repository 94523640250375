import React from 'react'

import GoogleVendor from './GoogleVendor'

export default function Vendors() {

  return (<>
    <GoogleVendor />
  </>)
}
