import React from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'
import { useRecoilValue } from 'recoil'

import Account from 'common/icons/Account'
import Author from 'common/icons/Author'
import Bookmark from 'common/icons/Bookmark'
import PostsIcon from 'common/icons/Posts'
import Button from 'components/Button'
import Link from 'components/Link'
import Text from 'components/Text'
import Div from 'layout/Div'
import { getCanUserCreatePost, getUser } from 'store/selectors/user.selectors'
import isEmpty from 'utils/isEmpty'
import userName from 'utils/userName'

const ProfileArea = ({ small }) => {

  const { t } = useTranslation()

  const user = useRecoilValue(getUser)
  const hasAccess = useRecoilValue(getCanUserCreatePost)

  if (isEmpty(user)) {
    return null
  }

  const to = `/profile${hasAccess ? '/posts/my' : ''}`
  const isAuthor = user.isAuthor

  if (small) {
    return <>
      <Button
        id="header-profile"
        hideDownMD
        text={userName(user)}
        to={to}
      />
      <Button
        id="header-profile"
        hideUpMD
        variant="icon"
        icon={<Account />}
        to={to}
      />
    </>
  }

  return (
    <Div sizeXS12 row ml10>
      <Div sizeXS12>
        <Link to="/profile">
          <Div row center mb10>
            <Account />
            <Text component="span" ml5 hideDownSM>
              {t('Profile')}</Text>
          </Div>
        </Link>
        <Link mb10 to="/profile/posts/saved">
          <Div row center mb10>
            <Bookmark />
            <Text component="span" ml5 hideDownSM>
              {t('Saved')}</Text>
          </Div>
        </Link>
        {!isAuthor && (
          <Link mb10 to="/profile/author">
            <Div row center mb10>
              <Author />
              <Text component="span" ml5 hideDownSM>
                {t('Become an Author')}</Text>
            </Div>
          </Link>
        )}
        {isAuthor && (
          <Link mb10 to="/profile/posts/my">
            <Div row center mb10>
              <PostsIcon />
              <Text component="span" ml5 hideDownSM>
                {t('My posts')}</Text>
            </Div>
          </Link>
        )}
      </Div>
    </Div>
  )

}

ProfileArea.propTypes = {
  small: PropTypes.bool
}

ProfileArea.defaultProps = {
  small: false
}

export default ProfileArea
