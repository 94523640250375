import React from 'react'
import { createRoot } from 'react-dom/client'
import { BrowserRouter } from 'react-router-dom'
import AppWrapper from 'AppWrapper'
import { RecoilRoot } from 'recoil'

import './index.css'

const container = document.getElementById('root')
const root = createRoot(container)

root.render(
  <BrowserRouter>
    <RecoilRoot>
      <AppWrapper />
    </RecoilRoot>
  </BrowserRouter>
)

