import React, { useEffect, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import PropTypes from 'prop-types'

import Button from 'components/Button'
import Text from 'components/Text'
import Div from 'layout/Div'

const DATA_INITIAL = {
  count: 0,
  next: 0,
  nextPage: 1
}

export default function LoadMore({ restCall, params, results, setResults, type, noResults }) {
  const [initialFetchDone, setInitialFetchDone] = useState(false)
  const data = useRef({
    ...DATA_INITIAL
  })
  const { t } = useTranslation()

  const fetch = (initial) => {
    restCall.all({
      ...params,
      page: data.current.nextPage
    }).then((response) => {
      if (!initialFetchDone) {
        setInitialFetchDone(true)
      }
      let page = data.current.nextPage
      if (response.next) {
        page = new URLSearchParams(new URL(response.next).search).get('page')
      }

      data.current = {
        count: response.count,
        next: response.next,
        nextPage: parseInt(page)
      }

      const resultsList = response.results.map((item) => {
        if (type) {
          return new type(item)
        }
        return item
      })

      if (initial) {
        setResults([
          ...resultsList
        ])
      } else {
        setResults([
          ...results,
          ...resultsList
        ])
      }
    })
  }

  useEffect(() => {
    data.current = {
      ...DATA_INITIAL
    }
    fetch(true)
  }, [JSON.stringify(params)])

  const getChildren = () => {

    if (data.current.count === 0) {
      if (noResults) {
        return noResults
      }
      return <Text component="p">
        {t('No results found.')}
      </Text>
    }

    if (!data.current.next) {
      return <Text component="p">
        {t('You have reached the end.')}
      </Text>
    }

    return (
      <Button id='load-more' text={t('Load More')}
        onClick={fetch}
      />
    )
  }

  if (!initialFetchDone) {
    return null
  }

  return <Div sizeXS12 center>
    {getChildren()}
  </Div>

}

LoadMore.propTypes = {
  restCall: PropTypes.object.isRequired,
  params: PropTypes.object,
  results: PropTypes.array.isRequired,
  setResults: PropTypes.func.isRequired,
  type: PropTypes.func,
  noResults: PropTypes.any
}

LoadMore.defaultProps = {
  params: {},
  type: null,
  noResults: null
}
