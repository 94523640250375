import { selector } from 'recoil'

import { userAtom } from 'store/atoms/user.atom'
import { AuthorStage } from 'types/user'
import isEmpty from 'utils/isEmpty'

export const getUser = selector({
  key: 'getUser', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    const user = get(userAtom)
    return isEmpty(user) ? {} : user
  },
})

export const getIsUserLoggedIn = selector({
  key: 'user/getIsUserLoggedIn', // unique ID (with respect to other atoms/selectors)
  get: ({ get }) => {
    return !isEmpty(get(userAtom))
  },
})

export const getCanUserCreatePost = selector({
  key: 'user/getCanUserCreatePost',
  get: ({ get }) => {
    const user = get(userAtom)
    if (isEmpty(user)) {
      return false
    }
    const { user_author: { stage } = { stage: AuthorStage.NONE } } = user
    return stage === AuthorStage.APPROVED
  },
})

export const getIsAdmin = selector({
  key: 'user/getIsAdmin',
  get: ({ get }) => {
    const user = get(userAtom)
    if (isEmpty(user)) {
      return false
    }
    return user.isAdmin
  },
})

export const getFollowedTags = selector({
  key: 'user/getFollowedTags',
  get: ({ get }) => {
    const user = get(userAtom)
    if (isEmpty(user)) {
      return []
    }
    return user.followedTags
  },
})

export const getFollowedUsers = selector({
  key: 'user/getFollowedUsers',
  get: ({ get }) => {
    const user = get(userAtom)
    if (isEmpty(user)) {
      return []
    }
    return user.followedUsers
  },
})
