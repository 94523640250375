import React from 'react'
import { createUseStyles } from 'react-jss'
import classNames from 'classnames'
import PropTypes from 'prop-types'

import Layout, { LayoutBase } from 'layout/Layout'
import isEmpty from 'utils/isEmpty'

import styles from './Text.styles'

const useStyles = createUseStyles(styles)

const Text = ({
  component, color, disabled, bold, center, ellipsis, rows, ...restPros
}) => {
  const classes = useStyles({ rows })
  const isClickable = !isEmpty(restPros.onClick)
  return (
    <Layout
      component={component}
      className={classNames(
        classes.default,
        classes[component],
        classes[color],
        {
          [classes.disabled]: disabled,
          [classes.bold]: bold,
          [classes.center]: center,
          [classes.clickable]: isClickable,
          [classes.ellipsis]: ellipsis,
          [classes.rows]: rows
        },
      )}
      {...restPros}
    />
  )
}

Text.propTypes = {
  component: PropTypes.oneOf(['h1', 'h2', 'h3', 'h4', 'h5', 'h6', 'p', 'span', 'small', 'li', 'em', 'pre']).isRequired,
  color: PropTypes.oneOf(['primary', 'secondary', 'background']),
  disabled: PropTypes.bool,
  bold: PropTypes.bool,
  center: PropTypes.bool,
  ellipsis: PropTypes.bool,
  rows: PropTypes.number,
  ...LayoutBase
}

Text.defaultProps = {
  color: 'primary',
  disabled: false,
  bold: false,
  center: false,
  ellipsis: false,
  rows: 0
}

export default Text
