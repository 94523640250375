import React from 'react'
import { useTranslation } from 'react-i18next'
import { createUseStyles } from 'react-jss'
import { useNavigate } from 'react-router-dom'
import { useRecoilValue, useSetRecoilState } from 'recoil'

import cookie from 'common/cookie'
import Login from 'common/icons/Login'
import Logout from 'common/icons/Logout'
import AdminSettingsBtn from 'components/AdminSettingBtn'
import CreatePostBtn from 'components/CreatePostBtn'
import Link from 'components/Link'
import Logo from 'components/Logo'
import Notification from 'components/Notification'
import ProfileArea from 'components/ProfileArea'
import Div from 'layout/Div'
import { userAtom } from 'store/atoms/user.atom'
import { getIsUserLoggedIn } from 'store/selectors/user.selectors'

import Button from '../Button'
import DarkModeToggle from '../DarkModeToggle'

import styles from './Header.styles'

const useStyles = createUseStyles(styles)

const Header = () => {

  const classes = useStyles()

  const setUser = useSetRecoilState(userAtom)
  const isLoggedIn = useRecoilValue(getIsUserLoggedIn)

  const navigate = useNavigate()

  const { t } = useTranslation()

  const logout = () => {
    cookie.remove('token')
    setUser(null)
    navigate('/auth')
  }

  return (
    <Div sizeXS12 center className={classes.header}>
      <Div sizeXS12 spaceBetween className={classes.headerContainer}>
        <div className={classes.left}>
          <Link to="/" className={classes.logo}>
            <Logo />
          </Link>
        </div>

        {!isLoggedIn && (
          <Div row center>
            <DarkModeToggle />
            <Button
              id="login-for-small"
              variant="icon"
              hideUpMD
              icon={<Login />}
              to="/auth"
            />
            <Button
              id="login"
              hideDownMD
              text={t('Login')}
              to="/auth"
            />
            <Button
              id="register"
              hideDownMD
              ml10
              mr10
              text={t('Register')}
              large
              variant="filled"
              to="/auth/register"
            />
          </Div>
        )}
        {isLoggedIn && (
          <Div row center>
            <DarkModeToggle />
            <AdminSettingsBtn />
            <CreatePostBtn />
            <Notification />
            <ProfileArea small />
            <Button id="header-logout"
              onClick={logout}
              variant="icon"
              icon={<Logout />} />
          </Div>
        )}
      </Div>
    </Div>
  )
}

export default Header
