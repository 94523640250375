import React from 'react'
import { createUseStyles } from 'react-jss'
import PropTypes from 'prop-types'

import Text from 'components/Text'
import Div from 'layout/Div'
import date from 'utils/date'

import styles from './Date.styles'

/*
  TODO:
    * Post will happen
    * Post has happened on this day
    * Post happens on this day every (month/year)
 */
const useStyles = createUseStyles(styles, { name: 'PostDate' })
const Date = ({ display_on_day, display_on_month }) => {
  const classes = useStyles()
  if (!display_on_day && !display_on_month) {
    return null
  }

  return (
    <Div className={classes.lastUpdate} centerCenter>
      <Div>
        <Text component="p" center>
          {date.getMonthName(display_on_month)}
        </Text>
        {display_on_day && (
          <Text component="h4" center bold>
            {display_on_day}
          </Text>
        )}
      </Div>
    </Div>
  )
}

Date.propTypes = {
  display_on_day: PropTypes.string,
  display_on_month: PropTypes.string,
}
Date.defaultProps = {
  display_on_day: null,
  display_on_month: null
}

export default Date
