import { breakpoints } from 'layout/Layout'

export default (theme) => ({
  container: {
    paddingTop: theme.headerHeight,
    marginLeft: 12,
    marginRight: 12,
    [`@media (max-width: ${breakpoints.sm}px)`]: {
      marginLeft: 6,
      marginRight: 6,
    },
  },
  content: {
    minHeight: 'calc(100vh - 160px)',
    paddingBottom: 30,
    margin: '0 auto',
    maxWidth: 1024,
  },

})
