import React from 'react'
import { useTheme } from 'react-jss'
import PropTypes from 'prop-types'

const Icon = ({ fill }) => {
  const theme = useTheme()
  const fillColor = fill || theme.white
  return (
    <svg fill="none"
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      height="18" viewBox="0 0 24 24" width="18">
      <path d="M18 6 6 18M6 6l12 12" />
    </svg>
  )
}

Icon.propTypes = {
  fill: PropTypes.string
}

Icon.defaultProps = {
  fill: null
}

export default Icon
