import React from 'react'
import { ThemeProvider } from 'react-jss'
import { useRecoilValue } from 'recoil'

import { isDarkModeAtom } from 'store/atoms/shared.atom'
import theme from 'theme'

import App from './App'

const AppWrapper = () => {

  const isDarkMode = useRecoilValue(isDarkModeAtom)

  return (
    <ThemeProvider theme={isDarkMode ? theme.dark : theme.light}>
      <App />
    </ThemeProvider>
  )
}

export default AppWrapper
