import React from 'react'
import { useTheme } from 'react-jss'

const Icon = () => {
  const theme = useTheme()
  const fillColor = theme.text.primary
  return (
    <svg fill="none"
      stroke={fillColor}
      strokeLinecap="round"
      strokeLinejoin="round"
      strokeWidth={1}
      height="24" viewBox="0 0 24 24" width="24">
      <path d="M22 19a2 2 0 0 1-2 2H4a2 2 0 0 1-2-2V5a2 2 0 0 1 2-2h5l2 3h9a2 2 0 0 1 2 2z" />
    </svg>
  )
}
export default Icon
